import React from 'react'
import { Box } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

export const InfoBox = ({
  severity,
  title,
  message,
  hidden = false,
  style,
  action = null,
  closable = false,
  onClose = null,
  className = '',
}) => {
  if (hidden) return null
  return (
    <Alert
      severity={severity}
      style={style ? style : null}
      onClose={closable && onClose}
      className={className}
    >
      <AlertTitle>{title}</AlertTitle>
      <Box display="flex" flexDirection="column">
        {message}
        <Box display="flex" justifyContent="flex-end" width="100%">
          {action}
        </Box>
      </Box>
    </Alert>
  )
}
