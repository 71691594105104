import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { BasicListItem } from '../../../../ui'

export const IPAddressesViewer = ({ addresses, instType }) => {
  if (instType === 'IRC') {
    return addresses.map((address, i) => (
      <Grid key={i} container spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
        <Grid item xs={12} sm={address.anomalias?.length > 0 ? 6 : 12}>
          <Typography variant="subtitle1" gutterBottom>
            {address.cod_derivacion}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {`${address['tipo_via'] || ''} ${address['via'] || ''} ${address['numero'] || ''}, ${
              address['poblacion'] || ''
            } (${address['codigopostal'] || ''}), ${address['provincia'] || ''}` +
              '\n' +
              `${address['bloque'] ? ', Portal: ' + address['bloque'] : ''}` +
              `${address['escalera'] ? ', Escalera: ' + address['escalera'] : ''}`}
          </Typography>
          <BasicListItem {...{ label: 'Tipo de gas', value: address['tipo_gas'] }} />
          <BasicListItem
            {...{ label: 'Nº CUPS', value: address['count_cups'] || 'No hay información' }}
          />
          <BasicListItem
            {...{ label: 'Resultado de la inspección', value: address['resultado'] }}
          />
          {address.resultado === 'Con anomalías secundarias' ? (
            <BasicListItem
              {...{ label: 'Situación de suministro', value: address['situacion_suministro'] }}
            />
          ) : null}
          {address.resultado === 'Con anomalías principales' ||
          address.resultado === 'Con anomalías secundarias y principales' ? (
            <BasicListItem
              {...{ label: 'Situación de servicio', value: address['situacion_servicio'] }}
            />
          ) : null}
          {address.anomalias?.length ? (
            <BasicListItem
              {...{
                label: 'Oservaciones sobre las anomalías',
                value: address['observacion_anomalias'] || 'No hay información',
              }}
            />
          ) : null}
        </Grid>
        {address.resultado !== 'Correcto' ? (
          <Grid item xs={12} sm={6}>
            {address.anomalias.map((anomalie, j) => {
              return (
                <Typography key={j} variant="body2" gutterBottom>
                  - {anomalie.cod_anomalia} {anomalie.value}
                </Typography>
              )
            })}
          </Grid>
        ) : null}
      </Grid>
    ))
  } else {
    const address = addresses.length ? addresses[0] : {}
    return (
      <Grid container spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
        <Grid item xs={12} sm={address?.anomalias?.length > 0 ? 6 : 12}>
          <Typography variant="subtitle1" gutterBottom>
            {address.cups}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {`${address['tipo_via'] || ''} ${address['via'] || ''} ${address['numero'] || ''}, ${
              address['poblacion'] || ''
            } (${address['codigopostal'] || ''}), ${address['provincia'] || ''}` +
              '\n' +
              `${address.numero ? `, Número finca: ${address.numero}` : ''}` +
              `${address.bloque ? `, Portal: ${address.bloque}` : ''}` +
              `${address.escalera ? `, Escalera: ${address.escalera}` : ''}` +
              `${address.piso ? `, Piso: ${address.piso}` : ''}` +
              `${address.puerta ? `, Puerta: ${address.puerta}` : ''}`}
          </Typography>
          <BasicListItem {...{ label: 'Tipo de gas', value: address['tipo_gas'] }} />
          <BasicListItem
            {...{
              label: 'Resultado de la inspección',
              value: address['resultado'],
            }}
          />
          {address.resultado === 'Con anomalías principales' ||
          address.resultado === 'Con anomalías secundarias y principales' ? (
            <BasicListItem
              {...{
                label: 'Situación de servicio',
                value: address['situacion_servicio'],
              }}
            />
          ) : null}
          {address.anomalias?.length ? (
            <BasicListItem
              {...{
                label: 'Oservaciones sobre las anomalías',
                value: address['observacion_anomalias'] || 'No hay información',
              }}
            />
          ) : null}
        </Grid>
        {address.resultado !== 'Correcto' ? (
          <Grid item xs={12} sm={6}>
            {address.anomalias?.map((anomalie, j) => {
              return (
                <Typography key={j} variant="body2" gutterBottom>
                  - {anomalie.cod_anomalia} {anomalie.value}
                </Typography>
              )
            })}
          </Grid>
        ) : null}
      </Grid>
    )
  }
}
