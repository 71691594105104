export const INSPECTIONS_FIELDS = ({
  formState,
  onChange,
  combos,
  intl,
  onChangeAutocomplete,
}) => ({
  TIPO_INSTALACION: {
    required: true,
    value: formState['tipo_instalacion'],
    name: 'tipo_instalacion',
    title: intl.formatMessage({
      id: 'pages.inspections.fields.tipo_instalacion.label',
    }),
    onChange,
  },
  UNE: {
    required: true,
    fullWidth: true,
    value: formState['une'],
    name: 'une',
    title: intl.formatMessage({ id: 'pages.inspections.fields.une.label' }),
    values: combos['une']?.data || [],
    onChange,
  },
  CUPS: {
    required: true,
    value: formState['cups'],
    name: 'cups',
    title: intl.formatMessage({ id: 'pages.inspections.fields.cups.label' }),
    inputProps: { maxLength: 20 },
    onChange,
  },
  DIRECCION_EDITABLE: {
    required: true,
    value:
      `${formState.address?.tipo_via || ''} ${formState.address?.calle || ''} ${
        formState.address?.numero_finca || ''
      }, ${formState.address?.municipio || ''} (${formState.address?.cp || ''}), ${
        formState.address?.provincia || ''
      }` +
      `${formState.address?.portal ? `, Portal: ${formState.address?.portal}` : ''}` +
      `${formState.address?.escalera ? `, Escalera: ${formState.address?.escalera}` : ''}` +
      `${formState.address?.piso ? `, Piso: ${formState.address?.piso}` : ''}` +
      `${formState.address?.puerta ? `, Puerta: ${formState.address?.puerta}` : ''}`,
    name: 'direccion_completa',
    title: intl.formatMessage({ id: 'pages.inspections.fields.direccion_editable.label' }),
    disabled: true,
  },
  NOMBRE_TITULAR: {
    required: true,
    value: formState['nombre_titular'],
    name: 'nombre_titular',
    title: intl.formatMessage({ id: 'pages.inspections.fields.nombre_titular.label' }),
    onChange,
  },
  APELLIDO1_TITULAR: {
    required: formState['tipo_instalacion'] === 'IRI',
    value: formState['apellido_1_titular'],
    name: 'apellido_1_titular',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.apellido_1_titular.label',
    }),
    onChange,
  },
  APELLIDO2_TITULAR: {
    value: formState['apellido_2_titular'],
    name: 'apellido_2_titular',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.apellido_2_titular.label',
    }),
    onChange,
  },
  POTENCIA_INSTALACION: {
    required: true,
    fullWidth: true,
    value: formState['potencia'],
    name: 'potencia',
    title: intl.formatMessage({ id: 'pages.inspections.fields.potencia_instalacion.label' }),
    values: [
      { key: '<70', value: '<70 kW' },
      { key: '>=70', value: '>=70 kW' },
    ],
    onChange,
  },
  TIPO_GAS: {
    required: true,
    fullWidth: true,
    value: formState['tipo_de_gas'],
    name: 'tipo_de_gas',
    title: intl.formatMessage({ id: 'pages.inspections.fields.tipo_gas.label' }),
    values: combos['tipo_gas']?.data || [],
    onChange,
  },
  EMPRESA_COMERCIALIZADORA: {
    required:
      (formState['tipo_instalacion'] === 'IRI' || formState['tipo_instalacion'] === 'IND') &&
      formState['tipo_de_gas'] !== 'PROPANO',
    fullWidth: true,
    value: formState['comercializadora'],
    name: 'comercializadora',
    title: intl.formatMessage({ id: 'pages.inspections.fields.empresa_comercializadora.label' }),
    onChange: onChangeAutocomplete,
    renderOption: (option) => option.key,
    inputMinLength: 1,
    requestTimeout: 600,
  },
  EMPRESA_DISTRIBUIDORA: {
    required: true,
    fullWidth: true,
    value: formState['sociedad'],
    name: 'sociedad',
    title: intl.formatMessage({ id: 'pages.inspections.fields.empresa_distribuidora.label' }),
    values: combos['distribuidora']?.data || [],
    onChange,
  },
  DIRECCION: {
    value: formState['direcciones'],
    name: 'direcciones',
    title: intl.formatMessage({ id: 'pages.inspections.fields.direcciones.label' }),
    onChange,
  },
  INSTALADOR: {
    required: true,
    fullWidth: true,
    value: formState['instalador'],
    name: 'instalador',
    title: intl.formatMessage({ id: 'pages.inspections.fields.instalador.label' }),
    tooltip:
      'Contacta con un administrador de la empresa si el instalador que buscas no figura en la lista.',
    values: combos['inspectores']?.data || [],
    onChange,
  },
  RESULTADO_INSPECCION: {
    required: true,
    fullWidth: true,
    name: 'resultado_inspeccion',
    title: intl.formatMessage({
      id: 'pages.inspections.fields.resultado_inspeccion.label',
    }),
    values: combos['resultado_inspeccion']?.data || [],
    onChange,
  },
  ANOMALIAS: {
    fullWidth: true,
    name: 'anomalias',
    placeholder: 'Selección de anomalias',
    title: intl.formatMessage({ id: 'pages.inspections.fields.anomalias.label' }),
    onChange,
  },
  OBSERVACIONES_ANOMALIAS: {
    name: 'observacion_anomalias',
    multiline: true,
    rows: 3,
    inputProps: { maxLength: 250 },
    title: intl.formatMessage({ id: 'pages.inspections.fields.observacion_anomalias.label' }),
    onChange,
  },
  SITUACION_SERVICIO: {
    required: true,
    fullWidth: true,
    name: 'situacion_servicio',
    title: intl.formatMessage({
      id: 'pages.inspections.fields.situacion_servicio.label',
    }),
    values: combos['situacion_servicio']?.data || [],
    onChange,
  },
  SITUACION_SERVICIO_SECUNDARIAS: {
    required: true,
    fullWidth: true,
    name: 'situacion_servicio',
    title: intl.formatMessage({
      id: 'pages.inspections.fields.situacion_servicio.label',
    }),
    values: combos['situacion_suministro']?.data || [],
    onChange,
  },
  SITUACION_SUMINISTRO: {
    required: true,
    fullWidth: true,
    name: 'situacion_suministro',
    title: intl.formatMessage({
      id: 'pages.inspections.fields.situacion_suministro.label',
    }),
    values: combos['situacion_suministro']?.data || [],
    onChange,
  },
  FECHA_INSPECCION: {
    value: formState['fecha_inspeccion'],
    title: intl.formatMessage({ id: 'pages.inspections.fields.fecha_inspeccion.label' }),
    name: 'fecha_inspeccion',
    maxDate: Date.now(),
    allowKeyboard: false,
    onChange,
  },
  FECHA_JCA: {
    value: formState['fecha_jca'],
    title: intl.formatMessage({ id: 'pages.inspections.fields.fecha_jca.label' }),
    name: 'fecha_jca',
    maxDate: Date.now(),
    allowKeyboard: false,
    onChange,
  },
  CERTIFICADO: {
    value: formState['certificado'],
    name: 'certificado',
    title: intl.formatMessage({ id: 'pages.inspections.fields.certificado.label' }),
    fileProps: {
      maxSize: 10000000, // 10Mb
      accept: ['application/pdf'],
    },
    onChange,
  },
  JUSTIFICANTE: {
    value: formState['justificante'],
    name: 'justificante',
    title: intl.formatMessage({ id: 'pages.inspections.fields.justificante.label' }),
    fileProps: {
      maxSize: 10000000, // 10Mb
      accept: ['application/pdf'],
    },
    onChange,
  },
  NUMERO_FACTURA: {
    required: true,
    value: formState['numero_factura'],
    name: 'numero_factura',
    title: intl.formatMessage({ id: 'pages.inspections.fields.numero_factura.label' }),
    inputProps: { maxLength: 13, pattern: '^((?!#).)*$' },
    tooltip: "No puede contener el símbolo '#'.",
    onChange,
  },
  FECHA_FACTURA: {
    value: formState['fecha_factura'],
    title: 'Fecha emisión factura',
    name: 'fecha_factura',
    minDate: formState['fecha_inspeccion'],
    maxDate: Date.now(),
    allowKeyboard: false,
    onChange,
  },
  IMPORTE: {
    required: true,
    value: formState['importe'],
    name: 'importe',
    title: intl.formatMessage({ id: 'pages.inspections.fields.importe.label' }),
    units: '€',
    decimalScale: 2,
    maxValue: formState['tipo_instalacion'] === 'IRI' ? 999.99 : 0,
    onChange,
  },
  FACTURA: {
    value: formState['factura'],
    name: 'factura',
    title: intl.formatMessage({ id: 'pages.inspections.fields.factura.label' }),
    fileProps: {
      maxSize: 10000000, // 10Mb
      accept: ['application/pdf'],
    },
    onChange,
  },
  TICKET_COMBUSTION: {
    value: formState['ticket_combustion'],
    name: 'ticket_combustion',
    title: intl.formatMessage({ id: 'pages.inspections.fields.ticket_combustion.label' }),
    fileProps: {
      maxSize: 10000000, // 10Mb
      accept: ['application/pdf'],
    },
    onChange,
  },
  CO_AMBIENTE: {
    required: true,
    value: formState['co_ambiente'],
    name: 'co_ambiente',
    title: intl.formatMessage({ id: 'pages.inspections.fields.co_ambiente.label' }),
    units: 'ppm',
    decimalScale: 0,
    onChange,
  },
  CO_CORREGIDO: {
    required: true,
    value: formState['co_corregido'],
    name: 'co_corregido',
    title: intl.formatMessage({ id: 'pages.inspections.fields.co_corregido.label' }),
    units: 'ppm',
    decimalScale: 0,
    onChange,
  },
})

export const NEW_ADDRESS_FIELDS = ({ formState, onChange, combos, intl }) => ({
  TIPO_INSTALACION: {
    required: true,
    disabled: true,
    fullWidth: true,
    value: formState['tipo_instalacion'],
    values: combos['tipo_instalacion_ab']?.data || [],
    name: 'tipo_instalacion',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.tipo_instalacion.label',
    }),
    onChange,
  },
  UNE: {
    required: true,
    fullWidth: true,
    value: formState['une'],
    name: 'une',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.une.label',
    }),
    values: combos['une']?.data || [],
    onChange,
  },
  CUPS: {
    required: true,
    value: formState['cups'],
    name: 'cups',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.cups.label',
    }),
    inputProps: { maxLength: 20 },
    onChange,
  },
  TIPO_GAS: {
    required: true,
    fullWidth: true,
    value: formState['tipo_de_gas'],
    name: 'tipo_de_gas',
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.tipo_gas.label' }),
    values: combos['tipo_de_gas']?.data || [],
    onChange,
  },
  NOMBRE_TITULAR: {
    required: true,
    value: formState['nombre'],
    name: 'nombre',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.nombre_titular.label',
    }),
    onChange,
  },
  APELLIDO_1_TITULAR: {
    required: false,
    value: formState['apellido_1'],
    name: 'apellido_1',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.apellido_1_titular.label',
    }),
    onChange,
  },
  APELLIDO_2_TITULAR: {
    required: false,
    value: formState['apellido_2'],
    name: 'apellido_2',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.apellido_2_titular.label',
    }),
    onChange,
  },
  PROVINCIA: {
    required: true,
    value: formState['provincia'],
    name: 'provincia',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.provincia.label',
    }),
    onChange,
  },
  MUNICIPIO: {
    required: true,
    value: formState['municipio'],
    name: 'municipio',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.municipio.label',
    }),
    onChange,
  },
  CODIGO_POSTAL: {
    required: true,
    value: formState['cp'],
    name: 'cp',
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.cp.label',
    }),
    onChange,
  },
  // TIPO_VIA: {
  //   required: true,
  //   fullWidth: true,
  //   value: formState['tipo_via'],
  //   name: 'tipo_via',
  //   title: intl.formatMessage({ id: 'pages.inspections.fields.tipo_via.label' }),
  //   values: combos['tipo_via']?.data || [],
  //   onChange,
  // },
  TIPO_VIA: {
    required: true,
    value: formState['tipo_via'],
    name: 'tipo_via',
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.tipo_via.label' }),
    onChange,
  },
  CALLE: {
    required: true,
    name: 'calle',
    value: formState['calle'],
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.calle.label' }),
    onChange,
  },
  NUMERO_FINCA: {
    required: true,
    name: 'numero_finca',
    value: formState['numero_finca'],
    title: intl.formatMessage({
      id: 'pages.inspections.createAddressForm.fields.numero_finca.label',
    }),
    onChange,
  },
  PORTAL: {
    required: false,
    name: 'portal',
    value: formState['portal'],
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.portal.label' }),
    onChange,
  },
  ESCALERA: {
    required: false,
    name: 'escalera',
    value: formState['escalera'],
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.escalera.label' }),
    onChange,
  },
  PISO: {
    required: false,
    name: 'piso',
    value: formState['piso'],
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.piso.label' }),
    onChange,
  },
  PUERTA: {
    required: false,
    name: 'puerta',
    value: formState['puerta'],
    title: intl.formatMessage({ id: 'pages.inspections.createAddressForm.fields.puerta.label' }),
    onChange,
  },
})
