import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    isLoading: false,
    data: [],
    pending: 0,
    search: {},
    selectedRows: [],
    pagination: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setAppsData: (state, { payload }) => {
      state.data = payload.data
      state.pending = payload.pending
      state.search = payload.search
      state.pagination = payload.pagination
      state.isLoading = false
    },
    setSelectedApps: (state, { payload }) => {
      state.selectedRows = [
        ...state.selectedRows.filter(
          (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
        ),
        ...payload,
      ]
    },
    resetAppsData: (state) => {
      state.data = []
      state.pending = 0
      state.search = {}
      state.pagination = {}
      state.selectedRows = []
    },
  },
})

export const applicationsReducer = applicationsSlice.reducer

export const { setLoading, setAppsData, setSelectedApps, resetAppsData } = applicationsSlice.actions

export const setApplications = (searchParams, role) => (dispatch) => {
  const { _pagina = 1, _num_x_pagina = 20, ...restSearchParams } = searchParams
  const { get } = requests
  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
  }
  const endpoint = role === 'gremio' ? 'tabla_gremios' : 'tabla_instaladores'

  get(`/solicitudes/${endpoint}`, config)
    .then(({ data }) => {
      dispatch(
        setAppsData({
          data: data['solicitudes'],
          search: searchParams,
          pending: data['pendientes'],
          pagination: {
            total_paginas: data['total_paginas'],
            total_solicitudes: data['total_solicitudes'],
          },
        })
      )
    })
    .catch(({ response }) => {
      console.debug('Error recuperando solicitudes', response)
      dispatch(setLoading(false))
    })
  dispatch(setLoading(true))
}
