import { COMBUSTION_ANOM_CODES } from '../constants'

const IRCAdressModel = (address) => ({
  count_cups: address.count_cups,
  cod_derivacion: address.cod_derivacion,
  provincia: address.provincia,
  poblacion: address.municipio,
  codigopostal: address.cp,
  tipo_via: address.tipo_via || 'tipo_via',
  via: address.calle,
  numero: address.numero_finca,
  bloque: address.portal,
  escalera: address.escalera,
  anyo_fin_vigencia_ip3: address.anyo_fin_vigencia_ip3,
  criterio_aceptacion_ip3: address.criterio_aceptacion_ip3,
  motivo_rechazo_ip3: address.motivo_rechazo_ip3,
  tipo_gas: address.tipo_de_gas,
  tipo_instalacion: address.tipo_instalacion,
})

const notIRCAdressModel = (address) => ({
  cups: address.cups,
  nombre: address.nombre,
  apellido_1: address.apellido_1,
  apellido_2: address.apellido_2,
  provincia: address.provincia,
  poblacion: address.municipio,
  codigopostal: address.cp,
  tipo_via: address.tipo_via || 'tipo_via',
  via: address.calle,
  numero: address.numero_finca,
  bloque: address.portal,
  escalera: address.escalera,
  piso: address.piso,
  puerta: address.puerta,
  comercializadora: address.comercializadora,
  distribuidora: address.sociedad,
  anyo_fin_vigencia_ip3: address.anyo_fin_vigencia_ip3,
  criterio_aceptacion_ip3: address.criterio_aceptacion_ip3,
  motivo_rechazo_ip3: address.motivo_rechazo_ip3,
  tipo_gas: address.tipo_gas,
  tipo_instalacion: address.tipo_instalacion,
})

export const ipPostPayload = (formState, addresses, companyId) => ({
  id_empresa_ip: companyId,
  tipo_instalacion: formState.tipo_instalacion,
  une: formState.une,
  tipo_gas: formState.tipo_gas,
  nueva_direccion: !!formState.nueva_direccion,
  comercializadora: formState.comercializadora?.key,
  sociedad: formState.sociedad,
  titular: formState.nombre_titular,
  direcciones: addresses.map((address) => {
    return formState.tipo_instalacion === 'IRC'
      ? IRCAdressModel(address)
      : notIRCAdressModel(address)
  }),
})

export const ipPutPayload = (inspection, formState, addresses, companyId) => {
  const { estado, ...restInspection } = inspection
  let payload = {
    ...restInspection,
    id_empresa_ip: companyId,
    inspector_id: formState.instalador,
    fecha_inspeccion: formState.fecha_inspeccion,
    numero_fra: formState.numero_factura,
    fecha_fra: formState.fecha_factura,
    importe_fra: formState.importe,
    certificado_ip: {
      documento: formState.certificado.data.split(',')[1],
      nombre: formState.certificado.name.split('.')[0],
      tipo: 'certificado_ip',
      extension: formState.certificado.type,
    },
    direcciones: addresses.map((address) => {
      let formattedAddress =
        formState.tipo_instalacion === 'IRC' ? IRCAdressModel(address) : notIRCAdressModel(address)
      let addressesPayload = {
        ...formattedAddress,
        resultado: address.resultado_inspeccion,
      }
      if (
        address.resultado_inspeccion === 'Con anomalías principales' ||
        address.resultado_inspeccion === 'Con anomalías secundarias y principales' ||
        address.resultado_inspeccion === 'Con anomalías secundarias'
      ) {
        addressesPayload.situacion_servicio = address.situacion_servicio
      }
      if (address.resultado_inspeccion !== 'Correcto') {
        addressesPayload.anomalias = address.anomalias
        addressesPayload.observacion_anomalias = address.observacion_anomalias
      }
      return addressesPayload
    }),
  }
  if (formState.factura) {
    payload.factura_ip = {
      documento: formState.factura.data.split(',')[1],
      nombre: formState.factura.name.split('.')[0],
      tipo: 'factura_ip',
      extension: formState.factura.type,
    }
  }
  return payload
}

export const jcaValidatePayload = (formState, addresses, companyId) => {
  let payload = {
    id_empresa_ip: companyId,
    tipo_gas: addresses[0].tipo_de_gas,
    une: formState.une,
    tipo_instalacion: formState.tipo_instalacion,
    comercializadora: formState.comercializadora?.key,
    sociedad: formState.sociedad,
    nombre: addresses.nombre,
    apellido_1: addresses[0].apellido_1,
    apellido_2: addresses[0].apellido_2,
    direcciones:
      formState.tipo_instalacion === 'IRC'
        ? addresses.map((address) => ({
            ...IRCAdressModel(address),
            //anomalias: address.anomalias,
            situacion_suministro: address.situacion_servicio,
          }))
        : [
            {
              ...notIRCAdressModel(addresses[0]),
              //anomalias: addresses[0].anomalias,
              situacion_suministro: addresses[0].situacion_servicio,
            },
          ],
  }
  return payload
}

export const jcaNonConformityPayload = (formState, addresses, companyId) => {
  let payload = {
    id_empresa_ip: companyId,
    une: formState.une,
    tipo_instalacion: formState.tipo_instalacion,
    direcciones: addresses.map((address) =>
      formState.tipo_instalacion === 'IRC' ? IRCAdressModel(address) : notIRCAdressModel(address)
    ),
    motivo: formState.motivo,
  }
  if (formState.observaciones) {
    payload.observaciones = formState.observaciones
  }
  if (formState.justificante_jca) {
    payload.justificante_jca = {
      documento: formState.justificante_jca.data.split(',')[1],
      nombre: formState.justificante_jca.name.split('.')[0],
      tipo: 'justificante_jca',
      extension: formState.justificante_jca.type,
    }
  }
  if (formState.certificado_ip) {
    payload.certificado_ip = {
      documento: formState.certificado_ip.data.split(',')[1],
      nombre: formState.certificado_ip.name.split('.')[0],
      tipo: 'certificado_ip',
      extension: formState.certificado_ip.type,
    }
  }
  return payload
}

export const jcaPostPayload = (formState, addresses, companyId) => {
  let payload = {
    id_empresa_ip: companyId,
    tipo_gas: formState.tipo_gas,
    une: formState.une,
    tipo_instalacion: formState.tipo_instalacion,
    nueva_direccion: !!formState.nueva_direccion,
    direcciones: addresses.map((address) => {
      let formattedAddress = []
      if (formState.tipo_instalacion === 'IRC') {
        formattedAddress = IRCAdressModel(address)
      } else {
        formattedAddress = notIRCAdressModel(address)
      }
      formattedAddress.situacion_suministro = address.situacion_suministro
      if (!formState.nueva_direccion) {
        formattedAddress.anomalias = address.solvedAnomalies
        if (formattedAddress.anomalias.some((a) => COMBUSTION_ANOM_CODES[a.cod_anomalia])) {
          formattedAddress.co_ambiente = Number(address.co_ambiente)
          formattedAddress.co_corregido = Number(address.co_corregido)
          formattedAddress.ticket_combustion = {
            documento: address.ticket_combustion.data.split(',')[1],
            nombre: address.ticket_combustion.name.split('.')[0],
            tipo: 'ticket_combustion',
            extension: address.ticket_combustion.type,
          }
        }
      }
      return formattedAddress
    }),
    fecha_jca: formState.fecha_jca,
    justificante: {
      documento: formState.justificante.data.split(',')[1],
      nombre: formState.justificante.name.split('.')[0],
      tipo: 'justificante_jca',
      extension: formState.justificante.type,
    },
  }
  return payload
}
